import Vue from "vue";
import { ValidationProvider, ValidationObserver, localize, extend } from "vee-validate";
import ptBr from "vee-validate/dist/locale/pt_BR";
import * as rules from "vee-validate/dist/rules";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

localize("pt", ptBr);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
