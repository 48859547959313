export default [
  {
    path: "/",
    name: "home",
    component: () => import("modules/home"),
  },
  {
    path: "/survey",
    name: "survey",
    component: () => import("modules/survey"),
  },
  {
    path: "/lifechart",
    name: "lifechart",
    component: () => import("modules/lifechart"),
  },
  {
    path: "/softskill",
    name: "softskill",
    component: () => import("modules/softskill"),
  },
  {
    path: "/company/survey",
    name: "company",
    component: () => import("modules/company"),
  },
  {
    path: "/basic/survey",
    name: "basic",
    component: () => import("modules/basic"),
  },
  {
    path: "/:tenant",
    name: "TenantHome",
    component: () => import("modules/tenant/home"),
  },
  {
    path: "/:tenant/basic",
    name: "TenantBasicSurvey",
    component: () => import("modules/tenant/basic"),
  },
  {
    path: "/:tenant/company",
    name: "TenantCompanySurvey",
    component: () => import("modules/tenant/company"),
  },
  {
    path: "/:tenant/full",
    name: "tenant_full",
    component: () => import("modules/tenant/full"),
  },
];
