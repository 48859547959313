import Vue from "vue";
import Lottie from "lottie-vuejs/src/LottieAnimation.vue";
import axios from "core/plugins/axios";
import vuetify from "core/plugins/vuetify";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import VueMask from "v-mask";
import VueSweetalert2 from "vue-sweetalert2";
import routes from "@/routes";
import App from "@/App.vue";

import "core/plugins/vee-validate";
import "assets/scss/app.scss";

Vue.use(VueMask);
Vue.use(VueRouter);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Vue.component("Lottie", Lottie);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
